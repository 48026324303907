.home{
    height: 100vh;
    width: 100%;
    background: linear-gradient(
        to top left,
        #1e364c 0%,
        black 100%
      );
    display: flex;
    flex-direction: column;
}



  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');



  @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Pacifico&display=swap');

.home-header{
  z-index:3;
    color: white;
    font-size: 3rem;
    position: absolute;
    top: 0px; /*0*/
    left:10vw; /*15vw*/
    height: 140px;
    width: 300px;
    background-image: url(/public/images/jahnvi-shah-unscreen.gif);
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
}

.home-content{
    height: 100%;
    display: flex;
    z-index: 3;
    flex-direction: row;
    align-items: center;
}

.home-message{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin-left: 10vw;
}

.home-image{
    height: 70%;
    width: 40%;
    margin-top: 100px;
    margin-right: 12vw;
    background-image: url('/public/images/avatar.png');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
}

.about-me{
    font-family: 'IBM Plex Mono', monospace;
    color: #71E0F4;
    margin-top: 20px;
    font-size: large;
}

@font-face {
  font-family: 'myFont';  
  src: url('/public/font/EFCO\ Brookshire\ Regular.ttf') format('woff2'), 
       url('/public/font/EFCO\ Brookshire\ Regular.ttf') format('woff');    

}





@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Radley&display=swap');

.introduction{
    width: 800px;
    margin-top: 60px;
    color: white;
    font-size: 4rem;
    font-family: 'Radley', serif;
    span{
      /* text-decoration:wavy underline; */
      font-family: 'myFont', sans-serif;
      font-size: 5rem;
    }
}

.social-media-strip {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    
  }
  
  .social-media-strip a {
    text-decoration: none;
    color: #e2c550;
    margin-right: 20px;
    font-size: 36px;
    transition: color 0.3s ease;
  }
  
  .social-media-strip a:hover {
    color: #917e34;
  }
  
  @media (max-width:1367px) {

    .introduction{
      font-size: 4rem;
    }
    
  }

  .dhimotif{
    background-image: url('/src/images/logo.svg');
    position: absolute;
    top: 36px;
    left:6vw;
    height: 70px;
    background-size: cover;
    background-position: center;
    width: 40px;
}

.annoyed{
  margin-bottom: -40px;
  margin-left: 20px;
  height: 100px;
  object-fit: cover; 
}


@media (max-width:1081px){
  .about-me{
    font-size: 14px;
  }
  .home-image{
    margin-left:-12vw;
  }
  .introduction{
    font-size: 3rem;
    span{
      font-size: 5rem;
    }
  }
}


@media (max-width: 768px) {
  .home{
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }
  .home-header{
    position: absolute;
    top:-26px;
    left:23vw;
    width: 60%;
  }
  .dhimotif{
    background-image: url('/src/images/logo3.png');
    width: 70px;
    height: 50px;
    top:28px;
    left:80vw;
  }

  .home-content{
    margin: 0 40px;
    margin-top: 120px;
    max-width: 100%;
    height: auto;
    flex-direction: column-reverse;
    justify-content:center;
    align-items:center;
  }

  .home-image{
    /* margin-top: 14vh; */
    width: 100%;
    height: 50vh;
    background-position: center;
    background-size: cover;
    margin-top: 0;
    margin: auto;

  }
  .introduction{
    margin: 0;
    width: 100%;
    font-size: 2rem;
    span{
      font-size: 3rem;
    }
  }

  .home-message{
    margin: 0;
    height: auto;
    width: 100%;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
    
  .about-me{
    font-size: 1rem;
    height: 60px;
  }
  .social-media-strip{
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
    text-align: center;

    a{
      margin: 0 10px;
    }
  }
  .annoyed{
    height: 50px;
    margin-bottom: -14px;
    margin-left:14px;
  }

}


