@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Pacifico&display=swap');

.testimonials{
    position: relative;
    height: auto;
    width: 100%;
    background: linear-gradient(
        to top left,
        #1e364c 0%,
        black 100%
      );
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.testimonials-header{
  z-index:3;
    font-family: 'IBM Plex Mono', monospace;
    font-size: xx-large;
    position: absolute;
    top:50px;
    left:15vw;
    height:106px;
    color: #57697B;
}

.image-slider-container {
  z-index:3;
    width:80vw ;
    max-width: 1000px;
    max-height: 100%;
    margin: 10%;
    margin-top: 120px;
    margin-bottom: 120px;
  }
  
  #t1{
    background-image: url('/src/images/test1.jpeg');

  }
  #t2{
    background-image: url('/src/images/test2.jpg');

  }
  #t3{
    background-image: url('/src/images/test3.jpg');

  }


  .slider-item {
    .card {
      border-radius: 10px;
      border: 0;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      padding: 20px;
      text-align: center;
      max-height: 70vh;
      margin: 0 6px;
      max-width:18vw;
      transition: transform 0.3s ease-in-out;
      background-color: #e2c5505f;
  
      h3 {
        font-size: 1.5rem;
        margin-bottom: 10px;
        font-family: 'IBM Plex Mono', monospace;
      }
  
      p {
        font-size: 12px;
      }

  
      .rating {
        margin-top: 10px;
      }
    }
  }
  



  .testers{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 5;
    width: 100%;
    height: 400px;
    transition: transform 0.3s ease-in-out;
    
  }

  .testers:hover{
    transform: translateY(-60px);
  }
  .testers:hover .test-comment{
    display: block;
  }

  .tester-image{
    border: 4px solid #e2c550;
    border-radius: 50%;
    min-height: 200px;
    background-size: cover;
    background-position: center;
    min-width:200px ;
    margin-bottom: 10%;
  }

  .tester-details{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 5;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    
  }

  .test-comment{
    display: none;
 
  }


  @media (max-width:768px){
    .testimonials{
      align-items: center;
      justify-content: center;
    }
    .image-slider-container{
      max-width: 80vw;
      height: 60vh;
      justify-content: center;
    }
    .slider-item{
    .card{
      max-width:80%;
    }}
  }

