

.scroll-to-top-button {
    font-family: 'Poppins', sans-serif;
    position: fixed;
    top: 30px;
    right: 20px;
    background-color:  #e2c550;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    opacity: 0;
    z-index: 20;
    transition: opacity 0.3s ease;
  }
  
  .scroll-to-top-button.visible {
    opacity: 1;
  }
  
.scroll-to-top-button.visible:hover{
  opacity: 0.8;
}

@media (max-width: 768px) {
  .scroll-to-top-button{
    display: none;
  }
}