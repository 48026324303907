.publications{
    position: relative;
    height: 100vh;
    width: 100%;
    background: linear-gradient(
        to top left,
        #3C4956 0%,
        black 100%
      );
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.selected-book{
  display: flex;
  flex-direction: column;
  align-items: center;
  a{
    width: 100%;
  }
  .buy{
    margin:20px 0;
    width: 100%;
    padding: 6px 0;
    /* border-radius: 8px; */
    border: 0;
    border-radius: 2px;
    box-shadow: 0 0 4px #e2c550;
    background-color: transparent;
    color: #e2c550;
    font-weight: 600;
    background-color: #e2c550;
    color: black;
    font-family:'Poppins', monospace ;
    cursor: pointer;
    transition: filter 0.2s ease-in-out;
  }
}

.buy:hover{
  filter: brightness(0.5);
}

#grid-item6 {
  /* animation: scaleAnimation 1.8s infinite; */
  border: 2px solid #e2c550;
}

#grid-item7{
  /* animation: scaleAnimation 2s infinite; */
  border: 2px solid #e2c550;
}

@keyframes scaleAnimation {
  0% {
      transform: scale(1); /* Initial scale */
  }
  50% {
      transform: scale(1.02); /* Scale up to 1.1 at 50% of the animation */
  }
  100% {
      transform: scale(1); /* Back to the original scale at the end of the animation */
  }
}

.color-gallery-container {
  z-index:3;
    position: relative;
    margin-top: 100px;
    display: flex;
    padding: 5%;
    box-shadow: 0 0 30px #1e364c63;
    border: 2px solid #e2c550;
    border-radius: 12px;
    width: 80%;
    height: 50%;
    justify-content: space-between;
    align-items: center;
    color: #e2c550;
  }
  
  .publications{
    .color-gallery-container{
      position: relative;
    }

  }

  .grid-item.selected{
    box-shadow: 0 0 20px #e2c550;
    transition: box-shadow 0.3s ease-in-out;
  }

  .click-me{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0
  }

  @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Pacifico&display=swap');

  @media(max-width:1367px){
    .publications{
      .infomatics{
        p{
          font-size: 10px;
        }
      }
    .grid-container-a, .grid-container-c{
    span{
      font-size: 16px;
    }}
    }
  }

  .grid-container-a {
    
    display: grid;
    width: 30%;
    height: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: auto;
    /* margin-right: 10%; */
    position: relative;
    span{
      top:-40px;
      font-size: large;
      font-family: 'Poppins', monospace;
      width: 100%;
      text-align: center;
      position: absolute;
      color: #e2c550;
    }

  

  }


  .grid-container-c {
    display: grid;
    width: 10%;
    height: 100%;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin: auto;
    /* margin-right: 10%; */

    position: relative;
    span{
      top:-40px;
      font-size: large;
      font-family: 'Poppins', monospace;
      width: 100%;
      text-align: center;
      position: absolute;
      color: #e2c550;
    }
  }
  
  .grid-item {
    width: 100%;
    height: 100%;
    max-height: 190px;
    cursor: pointer;
    box-shadow: 2px 2px 2px #e2c550;
    object-fit: cover;
    transition: 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out;
  }

  .grid-item:hover{
    transform: scale(1.05);
  }
  
  .selected-color-info {
    flex: 1;    
    margin-left: 20px;
    margin: 40px 60px;
  }
  
  .selected-color-box {
    width: 100%;
    height: auto;
    border: 4px solid #e2c550;
  }

  .selected-book{
    margin:auto;
    width: 30%;
  }

  
  
  .info {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
  }

  .infomatics{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-left: 10%;
    font-family: 'Poppins', sans-serif;
   
  }

  .infomatics h2{
    margin: auto;
    margin-bottom: 10%;
    
  }
  
.publications-header{
    font-family: 'IBM Plex Mono', monospace;
    font-size: xx-large;
    position: absolute;
    top:50px;
    left:15vw;
    height:106px;
    color: #71E0F4;
}


@media(min-width:1919px){
  .grid-container-a{
    width: 32%;
  }
  .grid-container-c{
    width:11%
  }
  .grid-item{
    max-height: 100%;
  }
}
@media (max-width:1081px){
  .publications{
    span{
      font-size: medium;
    }

  }
  .infomatics{
    
    width: 90%;
    margin: auto;
    span{
      text-align: center;
      width:80%;
    }
    h2{
      font-size: 14px;
      margin-bottom: 10px;
    }
    p{
      font-size: 11px;
    }
    
  }
  .grid-container-a, .grid-container-c{
    width: auto;
    height: auto;
    margin: auto;
  }

  .grid-container-c{
    margin:0;
    min-width:120px
  }
  .grid-item{
    height: 140px;
    width: auto;
    margin: auto;
    max-width: 85px;
  }

  .infomatics{
    margin: 0;
    margin-left: 30px;
    p{
      width: 100%;
    }
  }

}
@media (max-width:768px){
  .publications{
    height: auto;
    width: 100vw;
  }
  .grid-container-c {
    
    height: auto;
    width: 80%;
    margin:auto;
    /* margin-bottom: 30px; */
    margin-top:50px;
    grid-template-columns: repeat(2, 1fr);

  }


  .grid-item{
    width: auto;
    max-height: 180px;
  }
  .color-gallery-container{
    margin:auto;
    margin-top: 120px;
    margin-bottom: 60px;
    flex-direction: column-reverse;
    height: auto;
    width: 80vw;
    padding: 20px;
  }
  .grid-container-a{
    
    height: auto;
    width: 100%;
    margin:auto;
    margin-bottom: 40px;

    .grid-item{
      width:100%;
      height: auto;
      max-height: 160px;
    }
  }
  .selected-color-info{
    width: 100%;
    height: 50%;
    margin: 50px auto;
  }
  .info{
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }
  .selected-book{
    width: 36%;
    height: 90%;
    margin-right: 10px;
  }
  
  .selected-color-box{
    max-height: 170px;
    margin-top: 30%;
  }

  .infomatics{
    font-size: 8px;
    width: 80%;
    margin: auto;
    span{
      text-align: center;
      width:80%;
    }
    h2{
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
}