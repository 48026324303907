.hobbies{
    position: relative;
    height: auto;
    width: 100%;
    background: linear-gradient(
        to top left,
        #3C4956 0%,
        black 100%
      );
    display: flex;
    flex-direction: column;
}


@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.hobbies-header{
    font-family: 'IBM Plex Mono', monospace;
    font-size: xx-large;
    position: absolute;
    top:50px;
    left:15vw;
    height:106px;
    color: #71E0F4;
}

.hobbies-content{
    z-index:3;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 40px;
    margin-top: 120px;
    margin-bottom: 40px;
}

.about-me-card{
    padding: 50px 20px;
    margin: auto;
    width: auto;
    max-width: 60vw;
    height: auto;
    max-height: 70%;
    display: flex;
    box-shadow: 0 0 20px #0000005f;
    background: linear-gradient(
        to top left,
        #e2c550 20%,
        white 80%
      );
    border: 6px solid #e2c550;
    justify-content: center;
    align-items: center;
}

.about-me-info{
    width:70%;
    margin: 20px ;
    height: auto;
    font-family: 'Radley',serif;
    color: #3C4956;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
    h1{
        font-family: 'Poppins',sans-serif;
        width: 100%;
        font-size: 3rem;
        text-align: center;
        margin-top: -6%;
    }
    p{
        width: 80%;
        margin:auto;
    }
}

.about-me-icons{
    width: 30%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    row-gap: 20px;
    column-gap: 20px;
}

.about-me-icon{
    filter: sepia(1);
    max-width:80px ;
    padding: 2px;
}




@media (max-width: 768px) {
    .hobbies{
        height: auto;
        flex-direction: column;
        justify-content:center;

    }
    .hobbies-content{
        margin: 120px 0;
        height: auto;
    }
    .about-me-card{
        padding: 20px 20px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .about-me-icons{
        margin: auto;
        width: 100%;
        grid-template-columns:repeat(4,1fr)
        
    }
    .about-me-icon{
        width: auto;
        max-height: 36px;
    }
    .about-me-info{
        width: 100%;
        margin-bottom: 40px;
        h1{
            font-size: 1rem;
        }
        p{
            width: 90%;
            font-size: 10px;
        }
    }
    
}