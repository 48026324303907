.contact{
    position: relative;
    height: 80vh;
    width: 100%;
    background: linear-gradient(
        to top left,
        #1e364c 0%,
        black 100%
      );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* ContactPage.css */
.contact-container {
  z-index:3;
    width:60%;
    max-width: 800px;
    height: 60%;
    margin: 50px auto;
    padding: 20px;
    background: linear-gradient(
        to top left,
        #e2c550 20%,
        white 80%
      );
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: black;
    
    font-family: 'Poppins', sans-serif;
    
  
  h1 {
    color: black;
    margin-bottom: 30%;
  }
  
  p {
    margin: 10px 0;
    line-height: 1.5;
  }
  
  span, a {
    color: #007bff;
    cursor: pointer;
  }
  
  span:hover, a:hover {
    text-decoration: underline;
  }
  
  a {
    text-decoration: none;
  }
  
  
}

.contact-details{
    margin-bottom: 10%;
}

.byebye{
  /* filter:saturate(0.5); */
    height:70%;
  width: auto;
}

  @media (max-width: 768px) {
    .contact-container {
      margin: 20px auto;
      padding: 10px;
    }
  }
  
  @media (max-width:768px){
    .contact-container{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h1 {
        color: black;
        margin-bottom: 20px;
      }
      p{
        font-size: 12px;
      }

    }
    .byebye{
      width: 80%;
      height: auto;
    }
  }