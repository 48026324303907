.achievements{
    position: relative;
    height: auto;
    padding: 50px 0 100px 0 ;
    width: 100%;
    background: linear-gradient(
        to top left,
        #1e364c 0%,
        black 100%
      );
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.achievements-header{
    font-family: 'IBM Plex Mono', monospace;
    font-size: xx-large;
    z-index:3;
    position: absolute;
    top:50px;
    left:15vw;
    height:106px;
    color: #71E0F4;
}

.grid-container-b {
    display: grid;
    width: 100%;
    max-width: 42vw;
    height: auto;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-right: 4%;
  }


  .overlaya{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 160px;
    z-index: 10;
    background-color: #000000d4;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Poppins', monospace;
    transition: opacity 0.3s ease-in-out;

    .click{
      margin-top: 20px;
      font-size: 10px;
    }
  }
  .grid-items {
    position: relative;
    width: 100%;
    height: 160px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    border: 2px solid #e2c550;


  }

  .grid-items-img{
    width: 100%;
    height: 160px;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }


  .grid-items.selected .overlaya{
    opacity: 0;
    
  }
  .selected-achievement-info{
    width: 100%;
  }

  .grid-items:hover .overlaya{
    opacity: 0;
  }

  .achievement-info {
    height: 100%;
    width:auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    .information{
      width: 80%;
      margin: auto;
      margin-top: 30px;
      height: auto;
      text-align: center;
    }

  }

  .selected-achievement {
    max-height: 40vh ;
    margin: auto;
    width: auto;
    object-position: center;
    object-fit: cover;
    border: 6px solid #e2c550;
    
  }

 
  @media (max-width:1367px) {
    .grid-container-b{
      max-width: 50vw;
    }
    .selected-achievement-info{
      max-width: 35%;
    }
    
  }
  

@media (max-width:768px){
  .achievements{
    height: auto;

  }
  .grid-container-b{
    height: 100%;
    width: 100%;
    margin:auto;
    margin-top: 20px;
    max-width: 100%;
    margin-bottom: 10px;
    grid-template-columns: repeat(4,1fr);
  }
  .grid-items{
    width: 100%;
    height: 66px;

    .overlaya{
      position: absolute;
      top: 0;
      left: 0;
      
    width: 100%;
    height: 66px;
    }


  }

  .grid-items-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    }

 .selected-achievement-info{
  min-height: 60vh;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }

  .selected-achievement{
    max-width: 90%;
    margin-top: 20px;
  }
  .information{
    width: 100%;
    margin: auto;
    font-family: 'Poppins',monospace;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2{
      font-size: 16px;
      margin: auto;
      max-width: 100%;
      text-align: center;
    }
    p{
      font-size: 10px;
    }
  }

  .overlaya{
    font-size: 8px;

    .click{
      margin-top: 8px;
      font-size: 6px;
    }
  }

  

}