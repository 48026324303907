.navbar{
    width: 63vw;
    height: 120px;
    position: absolute;
    z-index: 10;
    top:10px;
    left:35vw;
    background-image: url('/public/images/navbar.png');
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
    transition: transform 0.3s ease-in-out;
}

.nav-content{
    width: 90%;
    max-width: 48vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

@keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

.icon{
    margin-right: 20px;
    width: 50px;
    height: 70px;
    /* background-image: url('/public/images/logo.svg'); */
    background-image: url('/src/images/logo.svg');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
    cursor: pointer;
}

.icon:hover{
    animation: rotate-center 1s linear infinite;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.nav-button{
    background:none;
    border: 0;
    font-family: 'Radley', sans-serif;
    font-size: larger;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.nav-button:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

.nav-button:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #1e364c ;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .menu-toggle{
    display: none;
  }



@-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }


  @media (max-width: 768px) {
    .menu-toggle {
      display: block; /* Show the toggle button on small screens */
      left: 10px;
      top:26px;
      position: fixed;
      font-size: 24px;
      z-index: 30;
      border: 0;
      color: #e2c550;
      background: none;
    }
    .navbar{
      position: fixed;
      background-color: #958236;
      height: 100vh;
      width:50vw;
      position: fixed;
      left: 0;
      top: 0;
      border:0;
      max-width: 0;
      background-image: none;
      transition: max-width 0.3s ease-in-out;

    }

    .navbar.visible{
      border-right:4px solid #e2c550 ;

    }
    .visible {
      
      visibility: visible;
      max-width: 100%;
    }
    .nav-content.visible{
      transition: opacity 0.3s ease-in-out;
      visibility: visible;
      opacity: 1;
    }
    .icon{
      display: none;
    }
    .nav-content{
      visibility: hidden;
      transition: opacity 0.3s ease-in-out, visibility 0.5s ease-in-out;
      opacity: 0;
      margin-right: 20px;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    .nav-button{
      margin:10px 0;
    }
    
  }
  

  @media (max-width:1080px){
    .icon{
      margin-left: 20px;
    }
    
    .nav-content{
      font-size: 14px;
      max-width: 40vw;
    }
  }