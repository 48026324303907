/* Background.css */

#tsparticles {
    position: fixed;
    max-width: 100vw;
    /* z-index:2; */
    height: 100vh;
}

@media (max-width:768px) {
    #tsparticles{
        max-width: 768px;
    }
    
}
